import React from 'react';
// import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

// const socialLinks = {
//   facebook: 'https://www.facebook.com/EagleExMusic',
//   instagram: 'https://www.instagram.com/eagleexmachina/',
//   twitter: 'https://twitter.com/EagleExMusic',
//   youtube: 'https://www.youtube.com/channel/UCDXBSr5AXHfEfGhZJsaHg7A',
//   tikTok: 'https://www.tiktok.com/@eagleexmachina?lang=en',
// };

const SocialBtns = () => {
  return (
    <div className='socialIcons'>
      <a href='https://www.tiktok.com/@eagleexmachina'>
        <StaticImage
          src='../../assets/images/icons/tikTok.png'
          alt='Tik Tok'
          objectFit='fill'
          //width={64}
          //height={64}
        />
      </a>
      <a href='https://www.instagram.com/eagleexmachina/'>
        <StaticImage
          src='../../assets/images/icons/instagram.png'
          alt='Instagram'
          objectFit='fill'
          //width={64}
          //height={64}
        />
      </a>
      <a href='https://www.facebook.com/EagleExMusic'>
        <StaticImage
          src='../../assets/images/icons/facebook.png'
          alt='Facebook'
          objectFit='fill'
          //width={64}
          //height={64}
        />
      </a>

      <a href='https://apple.co/3eJXJHl'>
        <StaticImage
          src='../../assets/images/icons/appleMusic.png'
          alt='ITunes'
          objectFit='fill'
          //width={64}
          //height={64}
        />
      </a>
      <a href='https://twitter.com/EagleExMusic'>
        <StaticImage
          src='../../assets/images/icons/twitter.png'
          alt='Twitter'
          objectFit='fill'
          //width={64}
          //height={64}
        />
      </a>
      <a href='https://www.youtube.com/channel/UCDXBSr5AXHfEfGhZJsaHg7A'>
        <StaticImage
          src='../../assets/images/icons/youtube.png'
          alt='Youtube'
          objectFit='fill'
          //width={64}
          //height={64}
        />
      </a>
      <a href='https://spoti.fi/2NrqbCA'>
        <StaticImage
          src='../../assets/images/icons/spotify.png'
          alt='Spotify'
          objectFit='fill'
          //width={64}
          //height={64}
        />
      </a>
    </div>
  );
};

export default SocialBtns;
