import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import SocialBtns from './common/SocialBtns';

const Navbar = () => {
  return (
    <div className='navBar sticky'>
      <Link className='logo' to='#'>
        <StaticImage
          className='buttonEffect'
          src='../assets/images/logoWhite.png'
          alt='Eagle Ex Machine Logo'
          placeholder='blurred'
          width={350}
        />
      </Link>

      <SocialBtns />

      <nav className='navButtons'>
        <Link to='#album'>Album</Link>
        <Link to='#about'>About</Link>
        {/* <Link to='#showsSection'>Shows</Link> */}
        <Link to='#contact'>Contact</Link>
      </nav>
    </div>
  );
};

export default Navbar;
