import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const About = () => {
  return (
    <section id='aboutSection'>
      <a class='anchor' id='about' href='#about'>
        About
      </a>
      <article>
        <h1>About Eagle Ex Machina</h1>
        <p>
          Eagle Ex Machina is an alternative rock band based out of Vancouver, BC. The band was founded by
          multi-instrumentalist/singer-songwriter Matt Cook-Contois as a vehicle to combine a love of rock music and a
          traditional fiddle upbringing. The debut album of Eagle Ex Machina features an aggressive approach to song
          writing, guitar and violin proficiency, and a diverse set of influences to create a unique sonic signature.
        </p>
        <p>
          The musical journey of Matt Cook-Contois started on his traditional territory of Misipawistik Cree Nation
          through the Frontier Fiddling Program. A combination of classroom learning, the tutelage of his musician
          Grandfather, and years of practice culminated in back-to-back finals appearances at the Canadian Grand Masters
          Fiddling Championship.{' '}
        </p>
        <p>
          Matt then relocated to Vancouver and set his focus on performing and becoming a sessional recording artist. He
          got his introduction to the Vancouver music scene by joining the Clay Ravens, a local indie rock band. He also
          joined The Sheets, a talented cover band which holds a residency at the legendary Blarney Stone in Gastown.
          These musical projects allowed Matt to further hone his skills as a performer, songwriter, and singer.
        </p>
        <p>
          Eagle Ex Machina was created in 2020 to capture the musical identity Matt developed throughout his career. The
          soundscape of Eagle Ex Machina pay homage and draws on influences such as the Foo Fighters & City and Colour
          while incorporating a modern approach to Canadian Fiddling.
        </p>
      </article>
      <StaticImage
        className='aboutImage'
        src='../../assets/images/matt.jpg'
        alt='Rock singer in pink smoke'
        placeholder='blurred'
        width={800}
      />
    </section>
  );
};

export default About;
