import React from 'react';
import '../assets/styles/main.scss';
import tvsMP4 from '../assets/images/particles.mp4';
export default function Layout({ children }) {
  return (
    <main className='layout'>
      <video id='bgVideo' autoPlay={true} poster='true' muted='true' loop='true'>
        <source src={tvsMP4} type='video/mp4' />
      </video>
      {children}
    </main>
  );
}
