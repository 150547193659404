import React from 'react';

const Footer = ({ image }) => {
  var d = new Date();
  var n = d.getFullYear();

  return <div className='footerPic'>&#169; Eagle Ex Machina {n}</div>;
};

export default Footer;
