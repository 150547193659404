import React from 'react'
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/Layout'
import Navbar from '../components/Navbar'
import Landing from '../components/Landing'
import About from '../components/About/Index'
// import Banner from '../components/Banner'
// import Shows from '../components/Shows'
// import Album from '../components/Album'
import Contact from '../components/Contact/Index'
import Footer from '../components/Footer'

// const bannerImage1 = <StaticImage src='../assets/images/banner1.jpg' alt='' placeholder='blurred' />;
// const bannerImage2 = <StaticImage src='../assets/images/banner2.jpg' alt='' placeholder='blurred' />;
const bannerImage3 = <StaticImage src='../assets/images/banner3.png' alt='' placeholder='blurred' />;

// const shows = [
//   { id: 1, date: "03 Mar 21", time: "7pm", venue: "Bob's Country Bunker", location: "123 X Street" },
//   { id: 2, date: "04 Mar 21", time: "10pm", venue: "Internet", location: "The Internet" },
//   { id: 3, date: "22 Mar 21", time: "7pm", venue: "Ray's Rad Pl", location: "123 X Street" },
//   { id: 4, date: "03 Apr 21", time: "7pm", venue: "Beats Bunker", location: "123 X Street" },
// ]
// const shows = [];

// const products = [
//   { id: 1, name: "Album", description: "This is may album featuring...", link: "#album", imageLink: "https://via.placeholder.com/150", price: "$29.95" },
//   { id: 2, name: "Decal", description: "Everyone loves stickers", link: "#decal", imageLink: "https://via.placeholder.com/150", price: "$29.95" },
//   { id: 3, name: "T-Shirt", description: "Latest in 4 hole t-shirt technology", link: "#tshirt", imageLink: "https://via.placeholder.com/150", price: "$29.95" },
//   { id: 4, name: "Hat", description: "Put it on your head, it looks good too", link: "#Hat", imageLink: "https://via.placeholder.com/150", price: "$29.95" },
// ]


const IndexPage = () => {
  return (
    <Layout>
      <title>Eagle Ex Machina</title>
      <Navbar />
      <Landing />
      <iframe src="https://www.youtube.com/embed/lTtGgGBO7ds" title="Gold - Eagle Ex Machina" />
      {/* <Album productList={products} /> */}
      <About />
      {/* <Banner message={"Next Show: Bob's country bunker..."} image={bannerImage1} /> */}
      {/* <Shows showList={shows} /> */}
      {/* <Banner image={bannerImage2} /> */}
      <Contact />
      <Footer image={bannerImage3} />
    </Layout >
  )
}

export default IndexPage
