import React from 'react';
// import { StaticImage } from 'gatsby-plugin-image';
import SocialBtns from '../common/SocialBtns';

const Contact = () => {
  return (
    <section id='contactSection'>
      <a class='anchor' id='contact' href='#contact'>
        Contact
      </a>
      {/* <div id='contactImage'>
         <StaticImage src='../../assets/images/contact.jpg' alt='' placeholder='blurred' width={800} /> 
      </div> */}
      <article>
        <div id='contactInfo'>
          <h1>Contact</h1>
          <a href='mailto:info@EagleExMachina.com'>
            <h4 className='buttonEffect'>Info@EagleExMachina.com</h4>
          </a>
          <SocialBtns />
        </div>
      </article>
    </section>
  );
};

export default Contact;
