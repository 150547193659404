import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const Landing = () => {
  return (
    <div className='landing' id='top'>
      <div className='albumCard'>
        <a class='anchor' id='album' href='#album'>
          Album
        </a>
        <StaticImage
          src='../assets/images/eagleExMachinaCover.jpg'
          alt='Eagle Ex Machine Album Cover'
          placeholder='blurred'
        />
      </div>
      <div className='callToAction'>
        <h3>Listen Now:</h3>
        <a class='anchor' id='album' href='#album'>
          Album
        </a>
        <div className='listenNow'>
          <StaticImage className='buttonEffect' src='../assets/images/icons/spotify.png' alt='Link to Spotify' />
          <StaticImage className='buttonEffect' src='../assets/images/icons/youtube.png' alt='Link to Youtube' />
          <StaticImage className='buttonEffect' src='../assets/images/icons/appleMusic.png' alt='Link to Apple Music' />
        </div>
      </div>
    </div>
  );
};

export default Landing;
